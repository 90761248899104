import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  CircularProgress,
  Menu,
  ListItem,
  ListItemText,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  ReactDataGrid_default,
  showError,
} from "../components/common";

import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Scatter } from "react-chartjs-2";

/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import MySelect from "../components/MySelect";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import PortLogEditDialog from "./PortLogEditDialog";

import { format } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "VOYAGE DATA CHART",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          //console.log(context); //{dataIndex, dataset.data, dataset.dataList,datasetIndex}
          var item = context.dataset.dataList ? context.dataset.dataList[context.dataIndex] : null;
          var tooltip = item ? (item.tooltip ?? context.raw) : context.raw;
          return tooltip;
        },
        afterLabel: function (context) {
          return context.formatedValue;
        },
      },
    },
  },
};
const options_scatter = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "VOYAGE DATA CHART",
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          //console.log(context);
          return context.raw.label;
        },
      },
    },
  },
};

const config1 = {
  type: "scatter",
  datasets: [
    {
      label: "test",
      data: [
        {
          x: 1,
          y: 1,
        },
        {
          x: 2,
          y: 3,
        },
      ],
    },
  ],
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Scatter Chart",
      },
    },
  },
};
const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels: labels.map((item) => item),
  datasets: [
    {
      label: "Speed",
      data: labels.map(() => Math.random()),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => Math.random()),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      TripCode: "Voyage:",
      ReportTypeID: "Report type:",
      ReportTime: "Date:",
      Mode: "Mode:",
      DataScope: "View:",
      DataDisplay0: "List",
      DataDisplay1: "Total",
      VoyageCode2: "To Voy:",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      TripCode: "Voyage:",
      ReportTypeID: "Loại báo cáo:",
      ReportTime: "Ngày:",
      Mode: "Hiển thị",
      DataScope: "View:",
      DataDisplay0: "List",
      DataDisplay1: "Total",
      VoyageCode2: "To Voy:",
    };

const buttonLabels = englishUI
  ? {
      btnTimKiem: "Load",
      btnLoadConfig: "Load config",
      btnConfig: "Save config",
      btnAtSea: "At-Sea",
      btnReportData: "All",
      btnInport: "In-port",
      btnManu: "Manu",
      btnPortLog: "Port Log",
    }
  : {
      btnTimKiem: "Tìm kiếm",
      btnLoadConfig: "Cấu hình",
      btnConfig: "Ghi cấu hình",
      btnAtSea: "At-Sea",
      btnReportData: "All",
      btnInport: "In-port",
      btnManu: "Manu",
      btnPortLog: "Port Log",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/

/*-----------------------Cac bien su dung trong form ----------------------*/
const danhsach_DataScope = [
  {
    value: "Trip",
    label: "Trip",
  },
  {
    value: "Voyage",
    label: "Voyage",
  },
];

////////////////////////////////-----------------------------------////////////////////////////////////
class VoyageDataInGraphPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Voyage: [], DM_VoyageTrip: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      DanhSachNgay: [],
      NgayChon: undefined, //ngay thang duoc chon
      //searching
      sheetLoading: true,
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: 0,
              //danh sach cac tieu chi tim kiem
              VoyageCode: null,
              TripCode: null, //ma hanh trinh
              TripCode2: null,
              Ngay: new Date(), //thoi gian la ngay
              DataScope: "Voyage", //xem trong ngay, 1 hanh trinh, 1 chuyen
              Mode: "VoyageData", //sheet gi
              DataFilter: "All", //loc theo che do hay tat ca
              DataDisplay: 0, //0 là chi tiet, 1 la tinh tong
              RevisedRob: false,
              Office: false, //co bao gom du lieu office khong
              Speed: false,
              Slip: false,
              PowerME: false,
              BCA: false,
              RpmME: false,
              ConsFODOME: false,
              ConsLOME: false,
              ConsOnSpeed: false, //ty le tieu thu DOFO chia speed quy doi kieu scatter
              ConsOnSpeed2: false, //ty le tieu thu DOFO chia speed quy doi kieu line
              FromDate: null,
              ToDate: null,
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      CurrentWorksheet: {
        WorksheetID: 0,
        WorksheetCode: "VoyageData",
      },
      WorkbookConfig: {
        version: 0, //doi ve -1 de reset neu can
        JsonConfig: {},
      },
      VoyageData: [],
      IsSheetCreated: false, //sheet da tao xong ahy chua
      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      dataFilled: false, //cho biet du lieu duoc fill hay chua
    };
  }

  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy" &&
      fieldName !== "RevisedRob" &&
      fieldName !== "VoyageCode" &&
      fieldName !== "Office"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy || obj.TripCode !== undefined) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {
      this.doSearch();
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data

      this.loadDanhMuc(() => {
        this.doSearch();
      });
    }
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(nextFunc) {
    //load danh muc

    server
      .post("Data/DoRequest", {
        Function: "Proc_DanhMuc_GetByShip",
        ThamSo: {
          ShipID: this.props.ShipData.ShipID,
        },
      })
      .then((response) => {
        //xu ly du lieu de gan' danh sach ngay cho tung trip
        const danhSachNgay = response.DR_VoyageTripDay;
        const danhsachTrip = response.DR_VoyageTrip;
        var firstTrip = undefined;
        danhsachTrip.map((item) => {
          item.DanhSachNgay = [];
          danhSachNgay.map((ngay) => {
            if (ngay.TripID == item.TripID) {
              item.DanhSachNgay.push(ngay);
              ngay.Ngay = moment(ngay.TripDate).format("DD/MM/YYYY");
            }
          });
          if (firstTrip == undefined) {
            firstTrip = item;
          }
        });
        this.state.DanhSachNgay = firstTrip ? firstTrip.DanhSachNgay : [];
        this.state.NgayChon = firstTrip ? firstTrip.DanhSachNgay[0] : null;
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
            after: {
              ...this.state.after,
              TripCode: firstTrip ? firstTrip.TripCode : "",
              Ngay: this.state.NgayChon ? this.state.NgayChon.TripDate : null,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });

    //thuc hien load config excel
  }
  //khi thay doi sap xep cot

  //Thuc hien search
  doSearch = () => {
    try {
      this.setState({
        saving: true,
      });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DR_Voyage_GetGraph",
          ThamSo: {
            ShipID: this.props.ShipData.ShipID,
            TripCode: this.state.after.TripCode,
            TripCode2: this.state.after.TripCode2,
            Ngay: this.state.after.Ngay,
            DataScope: this.state.after.DataScope,
            Data: this.state.after.Data,
            DataFilter: this.state.after.DataFilter,
            RevisedRob: this.state.after.RevisedRob,
            Office: this.state.after.Office,
            FromDate: this.state.after.FromDate,
            ToDate: this.state.after.ToDate,
          },
        })
        .then((response) => {
          this.setState({
            saving: false,
            dataFilled: true,
            VoyageData: response.VoyageData,
          });
          //hien thi du lieu theo ngay
        })
        .catch((error) => {
          this.setState({ saving: false });
          showError(error);
        });
    } catch (ex) {
      showError(ex);
    }
  };

  getChartData() {
    var list = [];
    if (this.state.after.Speed) {
      list.push(
        {
          label: "Speed",
          data: this.state.VoyageData.filter(
            (item) => item.DataLine == "SH"
          ).map((item) => item.AvgSpeed),
          borderColor: "rgb(15, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Speed-Office",
          data: this.state.VoyageData.filter(
            (item) => item.DataLine == "DM"
          ).map((item) => item.AvgSpeedQD), //lay du lieu tu tau
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        }
      );
    }
    if (this.state.after.Slip) {
      list.push({
        label: "Slip",
        data: this.state.VoyageData.filter((item) => item.DataLine == "SH").map(
          (item) => item.SlipNS
        ),
        borderColor: "rgb(155, 199, 132)",
        backgroundColor: "rgba(155, 99, 132, 0.5)",
      });
    }
    if (this.state.after.PowerME) {
      list.push({
        label: "Power",
        data: this.state.VoyageData.filter((item) => item.DataLine == "SH").map(
          (item) => item.PowerMENS
        ),
        borderColor: "rgb(55, 99, 32)",
        backgroundColor: "rgba(55, 99, 32, 0.5)",
      });
    }
    if (this.state.after.BCA) {
      list.push({
        label: "BCA",
        data: this.state.VoyageData.filter((item) => item.DataLine == "SH").map(
          (item) => item.BCANS
        ),
        borderColor: "rgb(55, 129, 32)",
        backgroundColor: "rgba(5, 99, 32, 0.5)",
      });
    }
    if (this.state.after.RpmME) {
      list.push({
        label: "RPM",
        data: this.state.VoyageData.filter((item) => item.DataLine == "SH").map(
          (item) => item.RpmMENS
        ),
        borderColor: "rgb(55, 29, 32)",
        backgroundColor: "rgba(55, 29, 32, 0.5)",
      });
    }
    if (this.state.after.ConsFODOME) {
      //FODOME
      list.push({
        label: "Cons.ME",
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => item.ConsFODOME24hNS),
        borderColor: "rgb(155, 229, 32)",
        backgroundColor: "rgba(155, 229, 32, 0.5)",
      });
      //FM: chi ap dung tau nao co flow rieng cua ME
      if (this.props.ShipData.FMEnable.FlowME) {
        list.push({
          label: "Cons.FM",
          data: this.state.VoyageData.filter(
            (item) => item.DataLine === "FM"
          ).map((item) => item.ConsFODOME24hNS),
          borderColor: "rgb(125, 129, 32)",
          backgroundColor: "rgba(235, 229, 32, 0.5)",
        });
      }
      //DM
      list.push({
        label: "Cons.OFFICE",
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "DM"
        ).map((item) => item.ConsFODOME24hNS),
        borderColor: "rgb(55, 29, 32)",
        backgroundColor: "rgba(55, 29, 32, 0.5)",
      });
    }
    if (this.state.after.ConsLOME) {
      //FODOME
      list.push({
        label: "Cons.Cyl",
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => item.ConsCyl24hNS),
        borderColor: "rgb(155, 99, 32)",
        backgroundColor: "rgba(155, 99, 32, 0.5)",
      });
      //FM
      list.push({
        label: "Cons.Sys",
        dataList: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => item),
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => item.ConsSys24hNS),
        borderColor: "rgb(125, 129, 32)",
        backgroundColor: "rgba(125, 129, 32, 0.5)",
      });
    }
    if (this.state.after.ConsOnSpeed2) {
      //ty   le giua tieu thu va speed duoi dang x-y
      list.push({
        label: "Cons/Speed-ĐM",
        dataList: this.state.VoyageData.filter(
          (item) => item.DataLine === "DM"
        ).map((item) => ({
          ...item,
          tooltip:
            "P%=" +
            item.PowerMENS +
            "/Cons=" +
            item.ConsFODOME24hNS +
            "/Spd-QĐ=" +
            item.AvgSpeedQD +
            "/Date=" +
            moment(item.ReportDataTime).format("DD/MM"),
        })),
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "DM"
        ).map((item) =>
          myLib.round(item.ConsFODOME24hNS / (item.AvgSpeedQD ?? 1000000), 3)
        ),
        borderColor: "rgb(125, 229, 32)",
        backgroundColor: "rgba(125, 229, 32, 0.5)",
      });
      //ty   le giua tieu thu va speed thuc te dang x-y
      list.push({
        label: "Cons.24h/Speed-Ship",
        dataList: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => 
          ({
            ...item,
            tooltip:
              "P%=" +
              item.PowerMENS +
              "/Cons=" +
              item.ConsFODOME24hNS +
              "/Spd=" +
              item.AvgSpeed +
              "/Date=" +
              moment(item.ReportDataTime).format("DD/MM"),
          })
        ),
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) =>
          myLib.round(item.ConsFODOME24hNS / (item.AvgSpeed ?? 1000000), 3)
        ),
        borderColor: "rgb(225, 229, 32)",
        backgroundColor: "rgba(225, 229, 32, 0.5)",
      });
      //ty   le giua tieu thu va speed dinh muc dang x-y
      list.push({
        label: "Cons.24h/SpeedQĐ-Ship",
        dataList: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => ({
          ...item,
          tooltip:
            "P%=" +
            item.PowerMENS +
            "/Cons=" +
            item.ConsFODOME24hNS +
            "/Spd-QĐ=" +
            item.AvgSpeedQD +
            "/Date=" +
            moment(item.ReportDataTime).format("DD/MM"),
        })),
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) =>
          myLib.round(item.ConsFODOME24hNS / (item.AvgSpeedQD ?? 1000000), 3)
        ),
        borderColor: "rgb(225, 129, 32)",
        backgroundColor: "rgba(225, 129, 32, 0.5)",
      });
    }
    return {
      labels: this.state.VoyageData.filter(
        (item) => item.DataLine === "SH"
      ).map((item) =>
        this.state.after.TripCode2
          ? item.VoyageCode
          : moment(item.ReportDataTime).format("DD/MM")
      ),
      datasets: list,
    };
  }
  //tra ve du lieu cho chart kieu scarter
  getScatterChartData() {
    var list = [];
    if (this.state.after.ConsOnSpeed) {
      //ty le giua tieu thu va speed quy doi duoi dang scatter
      list.push({
        label: "Cons/Speed",
        data: this.state.VoyageData.filter(
          (item) => item.DataLine === "SH"
        ).map((item) => ({
          x: item.PowerMENS,
          y: item.ConsFODOME24hNS / (item.AvgSpeedQD ?? 1000000),
          label:
            "P%=" +
            item.PowerMENS +
            "/Cons=" +
            item.ConsFODOME24hNS +
            "/Spd=" +
            item.AvgSpeedQD +
            "/Date=" +
            moment(item.ReportDataTime).format("DD/MM"),
        })),
        borderColor: "rgb(155, 99, 32)",
        backgroundColor: "rgba(155, 99, 32, 0.5)",
        showLine: true,
      });
    }

    return {
      type: "scatter",
      datasets: list,
    };
  }
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    //console.log('render................');
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.TripCode}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MySelectFilter
                id={"TripCode"}
                key={"TripCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DR_VoyageTrip}
                optionLabel="TripCode"
                optionValue="TripCode"
                placeholder=""
                value={this.state.after}
                onChange={(data, record) => {
                  this.setState(
                    {
                      DanhSachNgay: record ? record.DanhSachNgay : [],
                    },
                    () => this.saveData(data)
                  );
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.ReportTime}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="FromDate"
                key="FromDate"
                fullWidth
                value={this.state.after.FromDate}
                onChange={(value) => {
                  this.saveField("FromDate", value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="ToDate"
                key="ToDate"
                fullWidth
                value={this.state.after.ToDate}
                onChange={(value) => {
                  this.saveField("ToDate", value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                label="Revised Rob"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.RevisedRob}
                    onChange={(event) => {
                      this.saveField("RevisedRob", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Cons.ME"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.ConsFODOME}
                    onChange={(event) => {
                      this.saveField("ConsFODOME", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel
                label="Cons.Cyl+Sys"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.ConsLOME}
                    onChange={(event) => {
                      this.saveField("ConsLOME", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>

            <Grid item xs={1}>
              {this.state.saving || this.sheetLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.VoyageCode2}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MySelectFilter
                id={"TripCode"}
                key={"TripCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DR_VoyageTrip}
                optionLabel="TripCode"
                optionValue="TripCode"
                placeholder=""
                value={{ TripCode: this.state.after.TripCode2 }}
                onChange={(data, record) => {
                  this.setState(
                    {
                      DanhSachNgay: record ? record.DanhSachNgay : [],
                    },
                    () =>
                      this.saveData({
                        TripCode2: data.TripCode,
                      })
                  );
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.DataScope}</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MySelect
                options={danhsach_DataScope}
                value={this.state.after.DataScope}
                optionLabel="label"
                optionValue="value"
                onChange={(event) =>
                  this.saveField("DataScope", event.target.value)
                }
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                color={
                  this.state.after.DataFilter == "All" ? "warning" : "primary"
                }
                fullWidth
                onClick={() => {
                  this.state.after.DataFilter = "All";
                  this.doSearch();
                }}
              >
                {buttonLabels.btnTimKiem}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Speed"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.Speed}
                    onChange={(event) => {
                      this.saveField("Speed", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="Slip"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.Slip}
                    onChange={(event) => {
                      this.saveField("Slip", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="PowerME"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.PowerME}
                    onChange={(event) => {
                      this.saveField("PowerME", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="BCA"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.BCA}
                    onChange={(event) => {
                      this.saveField("BCA", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                label="RPM"
                labelPlacement="end"
                fullWidth
                control={
                  <Checkbox
                    size="medium"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                    checked={this.state.after.RpmME}
                    onChange={(event) => {
                      this.saveField("RpmME", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={3}>
              <FormControlLabel
                label="Cons.FO+DO/Speed"
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    sx1={{ "& .MuiSvgIcon-root": { fontSize: 12 } }}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 10 },
                    }}
                    checked={this.state.after.ConsOnSpeed}
                    onChange={(event) => {
                      this.saveField("ConsOnSpeed", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                label="Cons.FO+DO/Speed (2)"
                labelPlacement="end"
                control={
                  <Checkbox
                    size="small"
                    sx1={{ "& .MuiSvgIcon-root": { fontSize: 12 } }}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 10 },
                    }}
                    checked={this.state.after.ConsOnSpeed2}
                    onChange={(event) => {
                      this.saveField("ConsOnSpeed2", event.target.checked);
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <div
              style={{
                height: parentHeight - 105,
                width: "90%",
              }}
            >
              {this.state.after.ConsOnSpeed ? (
                <Scatter
                  options={options_scatter}
                  data={this.getScatterChartData()}
                />
              ) : (
                <Line options={options} data={this.getChartData()} />
              )}
            </div>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.open_code_seach ? (
          <PagingSearchCodeDialog
            open={true}
            DieuKien={this.state.after}
            gridColumns={this.columns_grid}
            close={(record) => {
              this.setState({
                open_code_seach: false,
              });
            }}
          />
        ) : null}

        {this.state.open_generate_code ? (
          <GenerateCodeDialog
            open={true}
            close={(record) => {
              this.setState({
                open_generate_code: false,
              });
            }}
          />
        ) : null}
        {this.state.action_show_form ? (
          <DataEditDialog
            open={true}
            FormName={this.state.action_FormName}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({
                action_show_form: false,
                action_FormName: undefined,
              });
            }}
          />
        ) : null}
        {this.state.open_edit_departure ? (
          <DepartureReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_departure: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonatsea ? (
          <NoonAtSeaReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonatsea: false });
            }}
          />
        ) : null}
        {this.state.open_edit_arrival ? (
          <ArrivalReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_arrival: false });
            }}
          />
        ) : null}
        {this.state.open_edit_allfast ? (
          <AllfastReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_allfast: false });
            }}
          />
        ) : null}
        {this.state.open_edit_noonport ? (
          <NoonAtPortReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_noonport: false });
            }}
          />
        ) : null}
        {this.state.open_edit_shifting ? (
          <ShiftingReportEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={() => {
              this.setState({ open_edit_shifting: false });
            }}
          />
        ) : null}
        {this.state.show_edit_Portlog ? (
          <PortLogEditDialog
            open={true}
            ShipData={this.props.ShipData}
            defaultTripCode={this.state.after.TripCode}
            DanhMuc={this.state.DanhMuc}
            close={() => {
              this.setState({ show_edit_Portlog: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  VoyageDataInGraphPage
);
