import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
} from "@mui/material";

import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import localConfig from "local-storage";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import MySelect from "../components/MySelect";
import SimpleDialog from "../components/SimpleDialog";

import DepartureReportEditDialog from "./DepartureReportEditDialog";
import NoonAtSeaReportEditDialog from "./NoonAtSeaReportEditDialog";
import ArrivalReportEditDialog from "./ArrivalReportEditDialog";
import AllfastReportEditDialog from "./AllfastReportEditDialog";
import NoonAtPortReportEditDialog from "./NoonAtPortReportEditDialog";
import ShiftingReportEditDialog from "./ShiftingReportEditDialog";
import { formatNumber } from "./VoyageDataInGridPage_Grids";
import WarningConfirmDialog from "./WarningConfirmDialog";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const ResultStatusList = [
  {
    value: 0,
    label: "OK",
  },
  {
    value: 1,
    label: "Warning",
  },
];
const TrangThaiXL_List = [
  {
    TrangThaiXL: 0,
    TenTrangThai: "--Tất cả--",
  },
  {
    TrangThaiXL: 1,
    TenTrangThai: "Chưa xem",
  },
  {
    TrangThaiXL: 2,
    TenTrangThai: "Đã xem",
  },
  {
    TrangThaiXL: 3,
    TenTrangThai: "Đang XL",
  },
  {
    TrangThaiXL: 4,
    TenTrangThai: "Đã XL",
  },
  {
    TrangThaiXL: 5,
    TenTrangThai: "Đã duyệt",
  },
];
const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage number:",
      ReportTypeID: "Report type:",
      ResultName: "Warning:",
    }
  : {
      VoyageCode: "Số chuyến đi:",
      ReportTypeID: "Loại báo cáo:",
      ResultName: "Cảnh báo:",
    };
const buttonReports = [
  {
    ID: "DR",
    Label: "Departure Report",
    LabelEN: "Departure Report",
  },
  {
    ID: "NS",
    Label: "Noon At Sea Report",
    LabelEN: "Noon At Sea Report",
  },
  {
    ID: "AR",
    Label: "Arrival Report",
    LabelEN: "Arrival Report",
  },
  {
    ID: "AF",
    Label: "All Fast Report",
    LabelEN: "All Fast Report",
  },
  {
    ID: "NP",
    Label: "Noon At Port Report",
    LabelEN: "Noon At Port Report",
  },
  {
    ID: "SR",
    Label: "Shifting Report",
    LabelEN: "Shifting Report",
  },
];
const buttonLabels = englishUI
  ? {
      btnThem: "Add",
      btnRead: "Read",
      btnApprove: "Confirm/Cancel",
      btnTimKiem: "Search",
      btnReportError: "Failed Report",
    }
  : {
      btnThem: "Thêm",
      btnRead: "Đã đọc",
      btnApprove: "Xác nhận/Hủy",
      btnTimKiem: "Tìm kiếm",
      btnReportError: "BC lỗi",
    };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "CheckResultID",
    type: "number",
    headerAlign: "center",
    defaultWidth: 75,
    header: englishUI ? "ID" : "ID",
    render: undefined,
  },
  {
    name: "TripCode",
    type: "string",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Trip" : "Hành trình",
    render: undefined,
  },
  {
    name: "TimeCode",
    type: "string",
    headerAlign: "center",
    defaultWidth: 90,
    header: englishUI ? "Time" : "Loại báo cáo",
    render: undefined,
  },
  {
    name: "Report Time",
    type: "date",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Report time" : "Thời gian",
    render: (props) => {
      return props.data.ReportDataTime
        ? moment(props.data.ReportDataTime).format("HH:mm DD/MM/YYYY")
        : null;
    },
  },

  {
    name: "Result",
    type: "string",
    headerAlign: "center",
    defaultWidth: 100,
    header: englishUI ? "Status" : "Trạng thái",
    render: ({ data }) => dataStruct.WarningStatusList1[data.Result].Label,
  },
  {
    name: "CheckRuleID",
    type: "string",
    headerAlign: "center",
    defaultWidth: 120,
    header: englishUI ? "Rule" : "Mã quy tắc",
    render: undefined,
  },
  {
    name: "LeftValue",
    type: "number",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "Value",
    render: ({ data }) => formatNumber(data.LeftValue, 2),
  },
  {
    name: "RuleName",
    type: "string",
    headerAlign: "center",
    defaultWidth: 300,
    header: englishUI ? "Rule name" : "Quy tắc",
    render: undefined,
  },
  {
    name: "DailyReportID",
    type: "string",
    headerAlign: "center",
    textAlign: "end",
    defaultWidth: 80,
    header: "ID Report",
  },
  {
    name: "NguoiXN",
    type: "string",

    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Checked by" : "Người KT",
    render: undefined,
  },
  {
    name: "NguoiDuyet",
    type: "string",

    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Approved by" : "Người duyệt",
    render: undefined,
  },
  {
    name: "NgayCN",
    type: "date",

    headerAlign: "center",
    defaultWidth: 150,
    format: "HH:MM DD/MM/YYYY",
    header: englishUI ? "Runtime" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "CheckResultID", dir: 0, type: "number" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

////////////////////////////////////////////////////////////////////////////
class CheckResultPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: { DM_Voyage: [], DM_ReportType: [] }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: 20,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
              //danh sach cac tieu chi tim kiem
              VoyageCode: null,
              Result: 1,
              ShipID: props.ShipData.ShipID,
              WarningLevel: 0,
              ...TrangThaiXL_List[0],
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha
      QuyenXL: {
        //quyen xu ly canh bao
        PhuTrach1: global.hasRoles("R221") ? 1 : 0, //phu trach chuyen vien
        PhuTrach2: global.hasRoles("R222") ? 2 : 0, //phu trach phong
        PhuTrach3: global.hasRoles("R223") ? 3 : 0, //phu trach lanh dao
      },
      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      showPreview: localConfig.get("CheckResultPage.showPreview"),
    };
    this.state.hasQuyen =
      this.state.QuyenXL.PhuTrach1 +
        this.state.QuyenXL.PhuTrach2 +
        this.state.QuyenXL.PhuTrach3 >
      0;
    //neu la cap 3 thi chi hien thi cap 2,1
    if (
      this.state.QuyenXL.PhuTrach3 &&
      this.state.QuyenXL.PhuTrach1 + this.state.QuyenXL.PhuTrach2 == 0
    ) {
      this.state.after.WarningLevel = 2;
    }
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      false &&
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (true || obj.Ascending || obj.SortBy) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s);
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {
      this.doSearch();
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.DanhMuc.DM_Voyage = [];
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.loadDanhMuc(() => {
        this.doSearch();
      });
    }
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(nextFunc) {
    //load danh muc

    server
      .post("Data/DoRequest", {
        Function: "Proc_DanhMuc_GetByShip",
        ThamSo: {
          ShipID: this.props.ShipData.ShipID,
        },
      })
      .then((response) => {
        this.setState(
          {
            DanhMuc: {
              ...response,
            },
            after: {
              ...this.state.after,
            },
          },
          () => nextFunc()
        );
      })
      .catch((error) => {
        showError(error);
        nextFunc();
      });
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit, sortInfo }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve, eject) => {
      server
        .post("Data/DoRequest", {
          Function: "Proc_DR_CheckResult_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
            PhuTrach1: this.state.QuyenXL.PhuTrach1,
            PhuTrach2: this.state.QuyenXL.PhuTrach2,
            PhuTrach3: this.state.QuyenXL.PhuTrach3,
          },
        })
        .then((response) => {
          var tempReport = response.TempReport; //danh sach du lieu report co van de chua xu ly
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            DR_TempReport_Error: tempReport,
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };
  loadPreview() {
    //load de hien thi preview report

    this.setState({
      ActionGuide:
        this.state.rowSelected.Result === 0
          ? "OK"
          : this.state.rowSelected.ActionGuide,
      Remark:
        this.state.rowSelected == undefined ||
        this.state.rowSelected.Result === 0
          ? ""
          : this.state.rowSelected.Remark,
    });
    if (this.state.showPreview) {
      //load de hien thi preview report
      server
        .post("Data/DoRequest", {
          Function: "Proc_DailyReport_Get_PreviewAndChecks",
          ThamSo: {
            ReportID: this.state.rowSelected.ReportID,
            DailyReportID: this.state.rowSelected.DailyReportID,
          },
        })
        .then((response) => {
          this.setState({
            ReportContent:
              response.ReportContents.length > 0
                ? response.ReportContents[0].PreviewText
                : "",
          });
        })
        .catch((error) => {
          showError(error);
        });
    }
  }

  updateXuLy() {
    //load de hien thi preview report
    server
      .post("Data/DoRequest", {
        Function: "Proc_DR_CheckResult_Confirm",
        ThamSo: {
          CheckResultID: this.state.rowSelected.CheckResultID,
          Result: this.state.rowSelected.Result == 1 ? 2 : 1, //dao nguoc trang thai
        },
      })
      .then((response) => {
        this.setState(
          {
            show_confim_warning: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        showError(error);
      });
  }
  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={3}>
              <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <MySelectFilter
                id={"VoyageCode"}
                key={"VoyageCode"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Voyage}
                optionLabel="VoyageCode"
                optionValue="VoyageCode"
                placeholder=""
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>Status</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <MySelectFilter
                id={"cboTTtt"}
                key={"cboTT"}
                fullWidth
                options={TrangThaiXL_List}
                optionLabel="TenTrangThai"
                optionValue="TrangThaiXL"
                placeholder=""
                value={this.state.after}
                onChange={(item) => {
                  this.saveData(item);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.ResultName}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelect
                options={ResultStatusList}
                value={this.state.after.Result}
                optionLabel="label"
                optionValue="value"
                onChange={(event) =>
                  this.saveField("Result", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
                onRightClickMenu={(item) => {
                  if (item.menuName == "CodeSearch") {
                    this.setState({ open_code_seach: true });
                  }
                  if (item.menuName == "Columns") {
                    this.setState({ open_columns_define: true });
                  }
                  if (item.menuName == "Generate") {
                    this.setState({ open_generate_code: true });
                  }
                  if (item.menuName == "Reload") {
                    //load f5orm
                    this.loadForm(true);
                  }
                  if (item.menuName == "GetCode") {
                    //lay codejs
                    this.generateCodeReactJs();
                  }
                }}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={18}>
            <div>
              {" "}
              <ReactDataGrid
                style={{
                  height: this.state.showPreview
                    ? (parentHeight / 3) * 2 - 68 - 40
                    : parentHeight - 56 - 40,
                  fontSize: 12,
                }}
                showZebraRows={true}
                i18n={englishUI ? undefined : ReactDataGrid_i18n}
                columns={this.columns_grid}
                pagination={true}
                multiSelect={false}
                showHoverRows={false}
                checkboxColumn={{
                  render: (props) => (
                    <input
                      type="checkbox"
                      readOnly
                      checked={props.rowSelected}
                    ></input>
                  ),
                }}
                defaultLimit={20}
                limit={this.state.after.PageSize}
                skip={
                  (this.state.after.PageIndex - 1) * this.state.after.PageSize
                }
                onSkipChange={(skip) => {
                  this.saveField(
                    "PageIndex",
                    skip / this.state.after.PageSize + 1
                  );
                }}
                dataSource={this.state.search_dataSource}
                idProperty="CheckResultID"
                headerHeight={30}
                rowHeight={30}
                allowUnsort={false}
                defaultSortInfo={defaultSortInfo}
                sortInfo={this.state.sortInfo}
                onSortInfoChange={this.onSortInfoChange}
                onLimitChange={(v) => this.saveField("PageSize", v)}
                emptyText={loadDataError(
                  this.state.search_error,
                  this.state.search_result.data.length === 0,
                  this.state.errorMessage,
                  this.state.errorLog
                )}
                skipLoadOnMount={false}
                enableSelection={true}
                onSelectionChange={({ data, selected, unselected }) => {
                  var now = new Date().getTime();
                  var diff = now - this.lastClick;
                  if (diff > 300) {
                    this.setState(
                      { rowSelected: data, ActionGuide: "", Remark: "" },
                      () => this.loadPreview()
                    );
                  } else {
                    //double click
                    this.setState({
                      rowSelected: data,
                      rowDoubleClicked: data,
                    });
                  }
                  this.lastClick = now;
                }}
              ></ReactDataGrid>
            </div>
            <div>
              {this.state.showPreview ? (
                <OutlinedInput
                  id="Preview"
                  key="Preview"
                  fullWidth
                  placeholder=""
                  autoFocus={false}
                  value={this.state.ReportContent}
                  multiline
                  style={{
                    height: "100%",
                  }}
                  inputProps={{
                    style: {
                      height: parentHeight / 3,
                      overflow: "scroll",
                      backgroundColor: this.state.showPreview
                        ? "whitesmoke"
                        : "lightgray",
                      zIndex: 1,
                    },
                  }}
                ></OutlinedInput>
              ) : null}
            </div>
          </Grid>

          <Grid item xs={6}>
            <OutlinedInput
              id="Preview"
              key="Preview"
              fullWidth
              placeholder=""
              autoFocus={false}
              value={this.state.ActionGuide}
              multiline
              style={{
                height: "48%",
              }}
              inputProps={{
                style: {
                  height: "100%",
                },
              }}
            />
            <hr />
            <OutlinedInput
              id="Preview2"
              key="Preview2"
              fullWidth
              placeholder=""
              autoFocus={false}
              value={this.state.Remark}
              multiline
              style={{
                height: "48%",
              }}
              inputProps={{
                style: {
                  height: "100%",
                },
              }}
            />
          </Grid>

          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              {this.state.hasQuyen ? (
                <>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      disabled={this.state.rowSelected == undefined}
                      fullWidth
                      onClick={() => {
                        this.setState({
                          show_warning_confirm: true,
                        });
                      }}
                    >
                      Confirm
                    </Button>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={8}>
                <FormControlLabel
                  label="Show report content"
                  labelPlacement="end"
                  fullWidth
                  control={
                    <Checkbox
                      size="medium"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }}
                      checked={this.state.showPreview}
                      onChange={(event) => {
                        this.setState({
                          showPreview: event.target.checked,
                          showLog: event.target.checked
                            ? false
                            : this.state.showLog,
                        });
                        localConfig.set(
                          "CheckResultPage.showPreview",
                          event.target.checked
                        );
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        {this.state.show_warning_confirm && this.state.rowSelected ? (
          <WarningConfirmDialog
            open={true}
            edittingRecord={this.state.rowSelected}
            CheckRule={this.state.rowSelected.CheckRuleID}
            QuyenXL={this.state.QuyenXL}
            close={(ketqua) => {
              if (ketqua) {
                this.state.show_warning_confirm = false;
                this.doSearch();
              } else {
                this.setState({
                  show_warning_confirm: false,
                });
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(CheckResultPage);
