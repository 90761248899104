import * as React from "react";
import "./App.css";

import { createTheme, ThemeProvider, ThemeOptions } from "@mui/material/styles";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import frLocale from "date-fns/locale/vi";

import HomePage from "./statics/HomePage";
import VersionChecker from "./components/VersionChecker";

import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
const server = require("./lib/server");
const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#2366c4",
    },
    secondary: {
      main: "#6C757D",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
  },
  typography: {
    body1: {
      lineHeight: 0.75,
      letterSpacing: "0.02em",
      fontSize: "0.8rem",
      fontWeight: 400,
    },
    caption: {
      lineHeight: 0.9,
    },
    body2: {
      fontSize: "0.7rem",
    },
    h6: {
      fontSize: "1.2rem",
    },
    h4: {
      letterSpacing: "0.05em",
    },
    subtitle1: {
      lineHeight: 1.09,
    },
    overline: {
      lineHeight: 0.92,
    },
  },
  spacing: 4,
};

const componentOptions = {
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 2,
          "&-MuiAccordion-root.Mui-expanded": {
            marginBottom: 2,
            backgroundColor: "red",
          },
          backgroundImage: `url('/images/bgPageSub.jpg')`,
          color: "black !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          backgroundColor: "info",
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          backgroundColor: "info",
          "&.Mui-selected": {
            backgroundColor: "#42b6f5",
          },
        },
        startIcon: {
          "& > *:first-of-type": {
            fontSize: 14,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        input: {
          padding: 4,
          "&.Mui-disabled": {
            backgroundColor: "#edebeb",
          },
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 36,
        },
        scroller: {
          backgroundImage: `url('/images/tab.jpg')`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 4,
          minHeight: 32,
          "&.Mui-selected": {
            color: "white",
          },
        },
        labelIcon: {
          flexDirection: "row",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 132,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
          padding: 6,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: `url('/images/caption.jpg')`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        region: {
          backgroundImage: `url('/images/bgPageSub.jpg')`,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#5781de",
          color: "white",
          minHeight: 24,
          "&.Mui-expanded": {
            minHeight: 0,
          },
          "&-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 24,
          },
          backgroundImage: `url('/images/caption.gif')`,
          backgroundPosition: `0px -31px`,
        },
        content: {
          "&.Mui-expanded": {
            margin: 0,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          lineHeight: 1.2,
        },
      },
    },
  
  },
};
//.css-o4b71y-MuiAccordionSummary-content.Mui-expanded
const theme = createTheme({ ...themeOptions, ...componentOptions });
var versionChecked = false;
var isOfficeVersion = window.location.href.indexOf("office") > 0;
function App() {
 
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
      <VersionChecker />
        <HomePage />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
