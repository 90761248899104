/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import { Shower } from "@mui/icons-material";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "GRADE CII" : "HỆ SỐ CII";
const fieldLabels = englishUI
  ? {
      Nam: "Year",
      A: "Grade-A",
      B: "Grade-B",
      C: "Grade-C",
      D: "Grade-D",
    }
  : {
      Nam: "Năm",
      A: "Grade-A",
      B: "Grade-B",
      C: "Grade-C",
      D: "Grade-D",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, [
    "GradeA",
    "GradeB",
    "GradeC",
    "GradeD",
    "GradeE",
  ]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version

  return ret;
}
function newRecord(shipid, nam) {
  return {
    ShipID: shipid,
    Nam: nam,
  };
}
////////////////////////--------------/////////////////////////////////////////
export default class HeSoCIIEditDialog extends React.Component {
  constructor(props) {
    super(props);
    var shipID = props.edittingRecord.ShipID;
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
      DanhSach: [newRecord(shipID, null)],
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    if (this.props.edittingRecord !== undefined) {
      this.setState({ saving: true });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_Ship_CII_GetByShip",
          ThamSo: {
            ShipID: this.props.edittingRecord.ShipID,
          },
        })
        .then((response) => {
          this.setState({
            saving: false,
            DanhSach: response.DM_Ship_CII.concat(
              newRecord(this.props.edittingRecord.ShipID, null)
            ),
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = {};
      var action = this.props.edittingRecord ? "update" : "insert";
      var list = [];
      this.state.DanhSach.map((dm) => {
        if (dm.Nam) {
          list.push(dm);
        }
      });
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_Ship_CII_Update",
            ThamSo: {
              ShipID: this.props.edittingRecord.ShipID,
              DanhSach: list,
            },
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action);
            }
            alert("Cập nhật thành công!");
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };

  render() {
    var keys = Object.keys(fieldLabels);
    var stt = 1;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={5}>
              {keys.map((k) => (
                <Grid item xs={1}>
                  <FormLabel>{fieldLabels[k]}</FormLabel>
                </Grid>
              ))}

              {this.state.DanhSach.map((dm, index) => (
                <>
                  {keys.map((k) => (
                    <Grid item xs={1}>
                      <MyNumberEditor
                        id={k}
                        fullWidth
                        value={dm[k]}
                        onValueChange={(value) => {
                          dm[k] = value;
                          if (
                            index == this.state.DanhSach.length - 1 &&
                            value &&
                            k == "Nam"
                          ) {
                            this.state.DanhSach = this.state.DanhSach.concat(
                              newRecord(0, null)
                            );
                          }
                          this.setState({
                            DanhSach: this.state.DanhSach,
                          });
                        }}
                      />
                    </Grid>
                  ))}
                  
                </>
              ))}
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>

          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
