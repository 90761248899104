import * as React from "react";
const server = require("../lib/server");
export default function VersionChecker(props) {
  const [showVersion, setShowVersion] = React.useState(false);
  const [reload, setReload] = React.useState(0);
  const doCheck = () => {
    console.log("checking version...");
    server
      .getUrl("/version.txt")
      //.getUrl("http://localhost:3000/" + "version.txt")
      .then((response) => {
        console.log("server version:" + response);
        if (response !== window.myconfig.SPAVersion) {
          window.myconfig.ServerVersion = response;
          setShowVersion(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => doCheck(), []);
  React.useEffect(() => {
    const interval = setInterval(() => {
      doCheck();
    }, 180000);

    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };
  }, []);

  return showVersion ? (
    <div
      style={{
        backgroundColor: "yellow",
        color: "white",
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 10000,
      }}
    >
      <a href="#" onClick={() => window.location.reload(true)}>
        Click here to update version {window.myconfig.ServerVersion}
      </a>
    </div>
  ) : null;
}
