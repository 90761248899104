import * as React from "react";

import { styled, useTheme, withStyles, ThemeProvider } from "@mui/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Button, Menu, MenuItem, Icon, Badge } from "@mui/material";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TableViewIcon from "@mui/icons-material/TableView";
import ExploreIcon from "@mui/icons-material/Explore";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ForumIcon from "@mui/icons-material/Forum";
import WarningIcon from "@mui/icons-material/Warning";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

import FactCheckIcon from "@mui/icons-material/FactCheck";
import SummarizeIcon from "@mui/icons-material/Summarize";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import { Navigation } from "react-minimal-side-navigation";
import "./ReactMinimalSideNavigation.css";
//cac form
import MenuDanhMuc from "../danhmuc1/MenuDanhMuc";
import MenuUser from "../components/MenuUser";
import LoginDialog from "../phanquyen/LoginDialog";
import LoadingDialog from "../components/LoadingDialog";
import ModuleSelectDialog from "../components/ModuleSelectDialog";
import ManageDataPage from "../templates/ManageDataPage";
import TempReportPage from "../pages/TempReportPage";
import ReportDataPage from "../pages/ReportDataPage";
import VoyageDataInGridPage from "../pages/VoyageDataInGridPage";
import FormDefinePage from "../pages/FormDefinePage";
import ReportDataChonDialog from "../danhmuc/ReportDataChonDialog";
import ReportFormChonDialog from "../danhmuc/ReportFormChonDialog";
import ShipChonDialog from "../danhmuc1/ShipChonDialog";
import GraphChonDialog from "../danhmuc1/GraphChonDialog";
import VoyageChonDialog from "../danhmuc1/VoyageChonDialog";
import CheckRuleChonDialog from "../danhmuc1/CheckRuleChonDialog";
import CheckResultPage from "../pages/CheckResultPage";
import VoyageDataInGraphPage from "../pages/VoyageDataInGraphPage";
///khac
import PhanMemDialog from "../phanquyen/PhanMemDialog";
import NhomQuyenDialog from "../phanquyen/NhomQuyenDialog";
import ChangePassDialog from "../phanquyen/ChangePassDialog";
import UsersPage from "../phanquyen/UsersPage";
import BoPhanDialog from "../phanquyen/BoPhanDialog";
import SystemLogPage from "../phanquyen/SystemLogPage";
import { ToastContainer, toast } from "react-toastify";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import localConfig from "local-storage";

import { Stack } from "@mui/material";
import DinhMucPage from "../pages/DinhMucPage";
import { Help, HighlightSharp } from "@mui/icons-material";
import CheckDayPage from "../pages/CheckDayPage";
import { TestGrid } from "../pages/TestGrid";
import ReportingPage from "../pages/ReportingPage";
import EmailEditDialog from "../danhmuc1/EmailEditDialog";
import SystemLogsDialog from "../danhmuc1/SystemLogsDialog";
import SimpleDialog from "../components/SimpleDialog";

const server = require("../lib/server");

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  content: {
    height: `${window.innerHeight - 75}px`,
    backgroundColor: `rgb(227,245,255)`,
  },
  loadingModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  logo: {
    width: 65,
    height: 65,
  },
});
const englishUI = true;
function getTitle(page) {
  return page
    ? englishUI
      ? page.titleEN
        ? page.titleEN
        : ""
      : page.title
      ? page.title
      : ""
    : "";
}
function getSavedConfig() {
  let currentPage = localConfig.get("currentPage");
  let open = localConfig.get("drawerOpened");
  //console.log(currentPage, open);
  return {
    currentPage: currentPage ? currentPage : ListOfForms[0],
    drawerOpened: open === undefined || open === null || open === "true",
  };
}
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(16)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== "open";
  },
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: () => {
      return drawerWidth;
    },
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
/* Danh sach form chinh */

const ListOfForms = [
  {
    AccessRight: "R21",
    title: "Báo cáo của tàu",
    titleEN: "Ship Reports",
    shortTitle: "Báo cáo",
    shortTitleEN: "Report",
    id: "tempReport",
    icon: <MailIcon color="primary" />,
    FormName: "TempReportPage",
  },
  {
    AccessRight: "R22",
    title: "Dữ liệu hàng ngày",
    titleEN: "Daily data",
    shortTitle: "Dữ liệu",
    shortTitleEN: "Data",
    id: "reportdata",
    icon: <TableViewIcon color="primary" />,
    FormName: "DailyShipDataPage",
  },
  {
    AccessRight: "R23",
    title: "Cảnh báo dữ liệu",
    titleEN: "Warning",
    shortTitle: "Cảnh báo",
    shortTitleEN: "Warning",
    id: "warning",
    icon: <WarningIcon color="primary" />,
    FormName: "",
  },

  {
    AccessRight: "R25",
    title: "Biểu đồ dữ liệu",
    titleEN: "Technical Chart",
    shortTitle: "Biểu đồ",
    shortTitleEN: "Chart",
    id: "graph",
    icon: <StackedLineChartIcon color="primary" />,
  },

  {
    AccessRight: "R24",
    title: "Thống kê số liệu",
    titleEN: "Data reporting",
    shortTitle: "Thống kê",
    shortTitleEN: "Reporting",
    id: "chat",
    icon: <SummarizeIcon color="primary" />,
    FormName: "DailyShipReportPage",
  },
];
//tao menu theo danh sach tau
function buildNavMenu(danhsach) {
  var items = [];
  danhsach.map((tau) => {
    var subNav = [];
    ListOfForms.map((form) => {
      if (global.hasRoles(form.AccessRight)) {
        subNav.push({
          title: englishUI ? form.titleEN : form.title,
          itemId: tau.ShipID + "/" + form.id,
          elemBefore: () =>
            form.id === "warning" ? (
                form.icon
            ) : (
              form.icon
            ),
          ShipData: tau,
          Form: form,
        });
      }
    });
    //co quyen thi moi tao menu
    if (global.hasRoles(tau.ShipCode)) {
      items.push({
        title: tau.ShipName,
        itemId: tau.ShipID + "",
        elemBefore: () =>
          tau.CheckResult ? (
            <Badge badgeContent={tau.CheckResult} color="error">
              <DirectionsBoatIcon color="primary" />
            </Badge>
          ) : (
            <DirectionsBoatIcon color="primary" />
          ),
        subNav: subNav,
        ShipData: tau,
        Form: subNav.length ? subNav[0].Form : {},
      });
    }
  });
  return items;
}

class MainPageRaw extends React.Component {
  constructor(props) {
    super(props);
    this.mainDiv = React.createRef();
    this.setMainDiv = (element) => {
      this.mainDiv = element;
      if (this.mainDiv) {
        this.setState({
          parentHeight: this.mainDiv.clientHeight,
        });
      }
    };
    this.state = {
      checkingUser: true,
      checkingUserEnd: false,
      currentPage: null,
      drawerOpening: true,
      showDanhMuc: false,
      parentHeight: 200,
      WarningData: {
        ReportNew: 0,
        ReportAlert: 0,
        CheckAlert: 0,
      },
      SystemWarn: false,
      CurrentShip: {
        ShipID: 0,
        ShipCode: "",
        ShipName: "",
      },

      ShipMenuTarget: null, //menu lua chon tau
      DanhSachTau: [],
      DanhSachMenu: [], //danh sach item cau menu theo danh sach tau
      ActiveMenu: null, //menu duoc chon
    };
  }
  componentDidMount() {
    if (!this.state.userChecked) {
      this.doCheckUser();
      this.setState({ userChecked: true });
      //callback neu nhu token expire
      window.onUnAuthorize = () => {
        if (!this.state.showLogin) {
          this.setState({ showLogin: true });
        }
      };
    }
    this.timer = setInterval(this.loadCanhBao, 60000);
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  loadCanhBao = () => {
    //load  canh bao du lieu tu may chu
    if (global.User) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_Get_CanhBao",
          ThamSo: {},
        })
        .then((response) => {
          //hien thi notify cho tung tau tren thanh menu trai'
          let checkResults = response.CheckByShips;
          this.state.DanhSachMenu.map((menu) => {
            let find = checkResults.find(
              (ck) => menu.ShipData && menu.ShipData.ShipID == ck.ShipID
            );
            let find2 = response.CheckResult.find(
              (r) => menu.ShipData && menu.ShipData.ShipID == r.ShipID
            );
            if (menu.ShipData) {
              menu.ShipData.CheckCount = find ? find.CheckCount : 0;
              menu.ShipData.CheckResult = find2 ? find2.SoLuong : 0;
            }
          });
          this.setState({
            WarningData: response.DR_CheckSummary[0],
            SystemWarn: response.SystemLogs.length > 0,
          });
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  };
  loadDanhMucShip() {
    //load danh muc
    if (true) {
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_Ship_GetAll",
          ThamSo: {},
        })
        .then((response) => {
          //lay cau hinh FM
          response.DM_Ship.map(
            (ship) => (ship.FMEnable = JSON.parse(ship.FMEnable))
          );
          var menuList = buildNavMenu(response.DM_Ship);
          this.setState({
            DanhMuc: {
              ...response,
            },
            DanhSachTau: response.DM_Ship,
            CurrentShip: response.DM_Ship.length ? response.DM_Ship[0] : {},
            DanhSachMenu: menuList,
            ActiveMenu:
              menuList.length > 0 && menuList[0].subNav.length > 0
                ? menuList[0].subNav[0]
                : {},
            currentPage:
              menuList.length > 0 && menuList[0].subNav.length > 0
                ? menuList[0].subNav[0].Form
                : {},
          });
          //load canh bao
          this.loadCanhBao();
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  doCheckUser = () => {
    this.setState({
      checkkingUser: true,
      checkingUserEnd: false,
    });
    server
      .query("User/GetUser", {})
      .then((response) => {
        global.User = response;
        this.setState({
          checkingUser: false,
          checkingUserEnd: true,
          userName: response.UserName,
        });
        //hien thi form default
        //this.autoShowMdiForm();
        this.loadDanhMucShip();
      })
      .catch((error) => {
        console.log(error);
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          var code = obj.code;
          console.log(obj);
          if (code === 401) {
            //request login

            this.setState({
              checkingUser: false,
              checkingUserEnd: true,
              showLogin: window.myconfig.localLogin,
            });
            //window.location.assign(server.getPortalServer());
          } else {
            log = obj.traceLog;
            var log_detail = JSON.parse(log);
            this.setState({
              checkingUser: true,
              checkingUserEnd: true,
              message: log_detail.Message,
            });
            showError(error);
          }
        } catch (e) {
          message = error + "";
          this.setState({
            checkingUser: true,
            checkingUserEnd: true,
            message: message,
          });
          showError(error);
        }
      });
  };
  handleDrawerOpenClose = () => {
    this.setState({ drawerOpening: !this.state.drawerOpening });
  };
  render() {
    const { theme, classes } = this.props;
    //console.log(this.state.currentPage.FormName);
    if (this.mainDiv) {
      //console.log(this.mainDiv.current);
    }
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={this.state.drawerOpening}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpenClose}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {this.state.showSystemLog
                ? "System log"
                : getTitle(this.state.currentPage) +
                  " - " +
                  (this.state.CurrentShip.ShipName
                    ? this.state.CurrentShip.ShipName
                    : "")}
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              {this.state.SystemWarn ? (
                <Badge badgeContent={1} color="error">
                  <IconButton
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      padingLeft: 1,
                    }}
                    onClick={(event) => {
                      this.setState({ show_Logs: true });
                    }}
                  >
                    <WarningIcon style={{ color: "yellow" }} />
                  </IconButton>
                </Badge>
              ) : null}

              <MenuDanhMuc
                onClick={(item) => {
                  console.log(item);
                  var show = {};
                  show["show_" + item.name] = true;
                  this.setState(show);
                  if (item.name === "ReportData") {
                    this.setState({ showReportData: true });
                  }
                  if (item.name === "ReportForm") {
                    this.setState({ showReportForm: true });
                  }

                  //////////////////
                }}
              />
              {!this.state.checkingUser && !this.state.showLogin ? (
                <MenuUser
                  UserName={this.state.userName}
                  onClick={(action) => {
                    if (action === "logout") {
                      server.saveToken("", true);
                      this.setState({
                        tabList: [],
                        showLogin: window.myconfig.localLogin,
                        //an cac menu
                        DanhSachTau: [],
                        CurrentShip: {},
                        DanhSachMenu: [],
                        ActiveMenu: {},
                        currentPage: {},
                      });
                      /*
                    var url = server.getPortalServer();
                    if(url) {
                      window.location.assign(url);
                    }*/
                    }
                    if (action === "donvi") {
                      this.setState({ showDonvi: true });
                    }
                    if (action === "phanquyen") {
                      this.setState({ showPhanQuyen: true });
                    }
                    if (action === "phanmem") {
                      this.setState({ showPhanMem: true });
                    }
                    if (action === "nhomquyen") {
                      this.setState({ showNhomQuyen: true });
                    }
                    if (action === "password") {
                      this.setState({ showChangePass: true });
                    }
                    if (action === "system") {
                      this.setState({ showSystemLog: true });
                    }
                  }}
                />
              ) : null}
              <IconButton
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  padingLeft: 1,
                }}
                onClick={() => {
                  window.open("/help.pdf", "_blank");
                }}
              >
                <Help style={{ color: "white" }} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={this.state.drawerOpening}>
          <DrawerHeader>
            {this.state.drawerOpening ? (
              <>
                <a href="/" target="_blank">
                  <img
                    src="../logoCVHP.png"
                    alt="Maritime Solution"
                    style={{
                      width: 65,
                      height: 65,
                    }}
                  ></img>
                </a>
                <Stack
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ show_module_selector: true });
                  }}
                >
                  <Typography variant="h6" component="div">
                    NOON
                  </Typography>
                  <Typography variant="h6" component="div">
                    REPORT
                  </Typography>
                </Stack>
              </>
            ) : null}
          </DrawerHeader>
          <Divider />
          {this.state.drawerOpening ? (
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId={
                this.state.ActiveMenu ? this.state.ActiveMenu.itemId : ""
              }
              onSelect={(item) => {
                // maybe push to the route

                if (item.itemId.indexOf("/") >= 0) {
                  var ShipID = item.itemId.split("/")[0];
                  var find = this.state.DanhSachMenu.find(
                    (c) => c.itemId == ShipID
                  );
                  //tim sub nav
                  var findSub = find.subNav.find(
                    (sub) => sub.itemId == item.itemId
                  );
                  this.setState({
                    ActiveMenu: item,
                    CurrentShip: findSub.ShipData,
                    currentPage: findSub.Form,
                  });
                } else {
                  //default subnav
                  var find = this.state.DanhSachMenu.find(
                    (c) => c.itemId == item.itemId
                  );
                  this.setState({
                    ActiveMenu: find.subNav.length ? find.subNav[0] : {},
                    CurrentShip: find.subNav.length
                      ? find.subNav[0].ShipData
                      : {},
                    currentPage: find.subNav.length ? find.subNav[0].Form : {},
                  });
                }
              }}
              items={this.state.DanhSachMenu}
            />
          ) : (
            <List>
              {ListOfForms.map((item, index) => (
                <ListItemButton
                  key={item.id}
                  selected={item.id == this.state.currentPage.id}
                  style={{ paddingLeft: 0 }}
                  onClick={() => {
                    localConfig.set("currentPage", item);
                    this.setState({ currentPage: item });
                  }}
                >
                  <Box
                    style1={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "center",
                    }}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <ListItemIcon
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      {item.icon ? item.icon : <MailIcon color="primary" />}
                    </ListItemIcon>
                    <ListItemText
                      primary={englishUI ? item.shortTitleEN : item.shortTitle}
                      primaryTypographyProps={{ fontSize: "smaller" }}
                    />
                  </Box>
                </ListItemButton>
              ))}
            </List>
          )}

          <Divider />
        </Drawer>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            p: 1,
            height: window.innerHeight - 75,
          }}
        >
          <DrawerHeader />
          <div className="main" ref={this.setMainDiv}>
            {this.mainDiv ? (
              this.state.showSystemLog ? (
                <SystemLogPage parentHeight={this.state.parentHeight} />
              ) : this.state.currentPage && this.state.currentPage.id ? (
                this.state.currentPage.id == "tempReport" ? (
                  <TempReportPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                    onWarningChange={() => this.loadCanhBao()}
                  />
                ) : this.state.currentPage.id == "reportdata" ? (
                  <VoyageDataInGridPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "warning" ? (
                  <CheckResultPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "kiemsoat" ? (
                  <CheckDayPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "graph" ? (
                  <VoyageDataInGraphPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : (
                  <ReportingPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                )
              ) : null
            ) : null}
            {this.state.showDanhMuc ? (
              <ReportDataChonDialog
                open={true}
                disableChon={true}
                close={() => this.setState({ showDanhMuc: false })}
              />
            ) : null}
            {this.state.showLogin ? (
              <LoginDialog
                open={this.state.showLogin}
                close={() => {
                  this.setState(
                    {
                      showLogin: false,
                      userName: global.User.UserName,
                    },
                    () => this.loadDanhMucShip()
                  );
                  //this.autoShowMdiForm(); //bo qua autoshow neu login lai
                }}
              />
            ) : null}
            {this.state.showReportData ? (
              <ReportDataChonDialog
                open={this.state.showReportData}
                disableChon={true}
                close={(ok) => {
                  this.setState({ showReportData: false });
                }}
              />
            ) : null}
            {this.state.showReportForm ? (
              <ReportFormChonDialog
                open={this.state.showReportForm}
                disableChon={true}
                close={(ok) => {
                  this.setState({ showReportForm: false });
                }}
              />
            ) : null}
            {this.state.showPhanMem ? (
              <PhanMemDialog
                open={this.state.showPhanMem}
                close={() => {
                  this.setState({ showPhanMem: false });
                }}
              />
            ) : null}
            {this.state.showDonvi ? (
              <BoPhanDialog
                open={this.state.showDonvi}
                close={() => {
                  this.setState({ showDonvi: false });
                }}
              />
            ) : null}
            {this.state.showPhanQuyen ? (
              <UsersPage
                open={this.state.showPhanQuyen}
                close={() => {
                  this.setState({ showPhanQuyen: false });
                }}
              />
            ) : null}
            {this.state.showNhomQuyen ? (
              <NhomQuyenDialog
                open={this.state.showNhomQuyen}
                close={() => {
                  this.setState({ showNhomQuyen: false });
                }}
              />
            ) : null}

            {this.state.showChangePass ? (
              <ChangePassDialog
                open={this.state.showChangePass}
                close={() => {
                  this.setState({ showChangePass: false });
                }}
              />
            ) : null}
            {this.state.show_DM_Ship ? (
              <ShipChonDialog
                open={this.state.show_DM_Ship}
                disableChon
                close={() => {
                  this.setState({ show_DM_Ship: false });
                }}
              />
            ) : null}
            {this.state.show_DM_Graph ? (
              <GraphChonDialog
                open={this.state.show_DM_Graph}
                disableChon
                close={() => {
                  this.setState({ show_DM_Graph: false });
                }}
              />
            ) : null}
            {this.state.show_DM_Voyage ? (
              <VoyageChonDialog
                open={this.state.show_DM_Voyage}
                disableChon
                close={() => {
                  this.setState({ show_DM_Voyage: false });
                }}
              />
            ) : null}
            {this.state.show_DM_CheckRule ? (
              <CheckRuleChonDialog
                open={this.state.show_DM_CheckRule}
                disableChon
                close={() => {
                  this.setState({ show_DM_CheckRule: false });
                }}
              />
            ) : null}
            {this.state.show_Email ? (
              <EmailEditDialog
                open={this.state.show_Email}
                close={() => {
                  this.setState({ show_Email: false });
                }}
              />
            ) : null}
            {this.state.show_Logs ? (
              <SystemLogsDialog
                open={this.state.show_Logs}
                disableChon={true}
                close={() => {
                  this.setState({ show_Logs: false });
                  this.loadCanhBao();
                }}
              />
            ) : null}
          </div>
        </Box>
        {this.state.checkingUser ? (
          <LoadingDialog
            open={true}
            showRetry={this.state.checkingUserEnd}
            onRetry={() => this.doCheckUser()}
          />
        ) : null}
        {this.state.show_module_selector && (
          <ModuleSelectDialog
            open={true}
            close={() => {
              this.setState({ show_module_selector: false });
            }}
          />
        )}
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Box>
    );
  }
}

//const MainPageRawStyles = withStyles(styles, { withTheme: true })(MiniDrawer);
export default function MainPage(props) {
  return (
    <ThemeProvider theme={{}}>
      <MainPageRaw />
    </ThemeProvider>
  );
}
